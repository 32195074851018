// .form-group .label
$form-group-label-margins: (
        "bottom": 5px
);

// .form-group.label-large .label
$form-group-label-text-transform: none;
$form-group-label-color: $app-color-primary;
$form-group-label-large-label-font-size: 14px;

// .form-group .form-control
$form-control-placeholder-font-style: normal;
$form-control-border-color: transparent;
$form-control-border-radius: 0px;
$form-control-height: 48px;
$form-control-padding: 9px;
$form-control-font-size: 14px;
$form-control-line-height: 30px;
$form-control-background: #fff;
$form-control-disabled-background: #eeeeee;

// .form-group .form-control::placeholder
$form-control-placeholder-color: $app-color-primary;

[data-skin="redhat"] .form-group {
  & .form-control {
    background: transparent !important;
    border: 1px solid rgb(240, 240, 240) !important;
    border-bottom: 1px solid $app-color-primary-dark !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:focus {
      background: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid rgb(240, 240, 240) !important;
      border-bottom: 1px solid $app-color-primary-dark !important;
    }
  }
}